import React, { Component } from 'react'
import $ from 'jquery'
import ScrollMagic from '../../plugins/ScrollMagic.js'
import '../../fonts/fonts.css'
import './ContactUs.css'
import TweenLite, { Power2 } from 'gsap'
import { detect } from 'detect-browser'
import { gqlConnect } from 'react-connect-graphql-beta'
import { Col, Row } from 'reactstrap'
import { connect } from 'react-redux'
import {
  setFirstName,
  setLastName,
  setEmail,
  setPhoneNumber,
  setCompany,
  setMessage,
  submitInquiry,
  setStatusMessage,
} from './actions'
import * as EmailValidator from 'email-validator'

function scrollToElement(el) {
  $('#container1').animate({
    scrollTop:
      $('#container1').scrollTop() +
      $(`#${el.id}`).offset().top -
      $(window).height() * 0.25,
  })
}

class ContactUs extends Component {
  componentDidMount() {
    this.controller = new ScrollMagic.Controller(/*{container: "#container1"}*/)
    let container = document.getElementById('container1')
    this.controller.scrollTo(function (newpos, duration) {
      TweenLite.to(container, duration, {
        scrollTo: { y: newpos, autoKill: false },
        ease: Power2.easeOut,
      })
    })
  }

  onFocus(event) {
    const browser = detect()
    let el = event.target
    if (browser.name === 'ios') {
      setTimeout(() => {
        scrollToElement(el)
      }, 50)
    }
  }

  onChangeFirstName = (name) => {
    const { setFirstName, setStatusMessage } = this.props
    setFirstName(name)
    setStatusMessage('')
  }

  onChangeLastName = (name) => {
    const { setLastName, setStatusMessage } = this.props
    setLastName(name)
    setStatusMessage('')
  }

  onChangeEmail = (email) => {
    const { setEmail, setStatusMessage } = this.props
    setEmail(email)
    setStatusMessage('')
  }

  onChangePhone = (phone) => {
    const { setPhoneNumber, setStatusMessage } = this.props
    setPhoneNumber(phone)
    setStatusMessage('')
  }

  onChangeMessage = (message) => {
    const { setMessage, setStatusMessage } = this.props
    setMessage(message)
    setStatusMessage('')
  }

  onChangeCompany = (company) => {
    const { setCompany, setStatusMessage } = this.props
    setCompany(company)
    setStatusMessage('')
  }

  validateInquiry = (inquiry) => {
    let success = false
    let error = ''

    if (inquiry.firstName === '') {
      error = 'Please include your first name.'
    } else if (inquiry.lastName === '') {
      error = 'Please include your last name.'
    } else if (inquiry.company === '') {
      error = 'Please include your company/organization name.'
    } else if (inquiry.email === '') {
      error = 'Please include your email address.'
    } else if (!EmailValidator.validate(inquiry.email)) {
      error = 'Email address is not formatted correctly.'
    } else if (inquiry.message === '') {
      error = 'Please include a message.'
    } else {
      success = true
    }

    return {
      success: success,
      error: error,
    }
  }

  getUtmData = (utm) => {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get(utm)
  }

  onSubmit = () => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      message,
      company,
      submitInquiry,
      setStatusMessage,
      gql,
    } = this.props
    const { validateInquiry } = this
    const { hubspot } = gql
    const inquiry = {
      firstName,
      lastName,
      email,
      phone: phoneNumber || '',
      message,
      company,
      hsPortalId: hubspot?.data[0]?.hubspotPortalId,
      hsFormGuid: hubspot?.data[0]?.contactFormGuid,
      utmSource: this.getUtmData('utm_source'),
      utmMedium: this.getUtmData('utm_medium'),
      utmCampaign: this.getUtmData('utm_campaign'),
    }

    const validate = validateInquiry(inquiry)

    if (validate.success) {
      submitInquiry(inquiry)
    } else {
      setStatusMessage(validate.error)
    }
  }

  render() {
    const {
      onChangeFirstName,
      onChangeLastName,
      onChangePhone,
      onChangeEmail,
      onChangeMessage,
      onChangeCompany,
      onSubmit,
    } = this
    const { isSending, statusMessage } = this.props

    return (
      <div id={'contact-us-form'}>
        <div id={'contact-us-container'} style={this.props.style}>
          <Row>
            <Col id={'contact-us-header'}>
              <span id={'contact-us-header'}>Let's Talk</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <div id={'contact-us-alert'} style={{ display: 'none' }}>
                <span id={'contact-us-alert'} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} className={'contact-us'}>
              <input
                onChange={(e) => onChangeFirstName(e.target.value)}
                className={'contact-us'}
                id={'firstname'}
                onFocus={this.onFocus}
                placeholder={'First Name*'}
              />
              <span
                className={'contact-us-err contact-us-err-hide'}
                id={'firstname-err'}>
                x
              </span>
            </Col>
            <Col xs={12} md={6} className={'contact-us'}>
              <input
                onChange={(e) => onChangeLastName(e.target.value)}
                className={'contact-us'}
                id={'lastname'}
                onFocus={this.onFocus}
                placeholder={'Last Name*'}
              />
              <span
                className={'contact-us-err contact-us-err-hide'}
                id={'lastname-err'}>
                x
              </span>
            </Col>
          </Row>
          <Row>
            <Col className={'contact-us'}>
              <input
                onChange={(e) => onChangeCompany(e.target.value)}
                className={'contact-us'}
                id={'company'}
                onFocus={this.onFocus}
                placeholder={'Company*'}
              />
              <span
                className={'contact-us-err contact-us-err-hide'}
                id={'company-err'}>
                x
              </span>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} className={'contact-us'}>
              <input
                onChange={(e) => onChangeEmail(e.target.value)}
                className={'contact-us'}
                id={'email'}
                onFocus={this.onFocus}
                placeholder={'Email Address*'}
              />
              <span
                className={'contact-us-err contact-us-err-hide'}
                id={'email-err'}>
                x
              </span>
            </Col>
            <Col xs={12} md={6} className={'contact-us'}>
              <input
                onChange={(e) => onChangePhone(e.target.value)}
                className={'contact-us'}
                id={'phone'}
                onFocus={this.onFocus}
                placeholder={'Phone Number'}
              />
              <span
                className={'contact-us-err contact-us-err-hide'}
                id={'phone-err'}>
                x
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <textarea
                onChange={(e) => onChangeMessage(e.target.value)}
                className={'contact-us'}
                id={'message'}
                onFocus={this.onFocus}
                placeholder={'Message*'}
              />
              <span
                className={'contact-us-err contact-us-err-hide'}
                id={'message-err'}>
                x
              </span>
            </Col>
          </Row>
          <Row>
            <Col>
              <button
                className={
                  isSending ? 'submit-contact-us sending' : 'submit-contact-us'
                }
                onClick={() => onSubmit()}>
                {isSending ? 'Sending...' : 'Submit'}
              </button>
            </Col>
          </Row>
        </div>
        <div
          style={{
            fontFamily: 'Graphie',
            fontSize: 18,
            color: '#4d4e56',
            fontWeight: 400,
            marginTop: 15,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}>
          {statusMessage}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    firstName: state.contactUs.firstName,
    lastName: state.contactUs.lastName,
    email: state.contactUs.email,
    phoneNumber: state.contactUs.phoneNumber,
    message: state.contactUs.message,
    company: state.contactUs.company,
    isSending: state.contactUs.isSending,
    statusMessage: state.contactUs.statusMessage,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFirstName: (name) => dispatch(setFirstName(name)),
    setLastName: (name) => dispatch(setLastName(name)),
    setEmail: (email) => dispatch(setEmail(email)),
    setPhoneNumber: (phone) => dispatch(setPhoneNumber(phone)),
    setCompany: (company) => dispatch(setCompany(company)),
    setMessage: (message) => dispatch(setMessage(message)),
    submitInquiry: (inquiry) => dispatch(submitInquiry(inquiry)),
    setStatusMessage: (message) => dispatch(setStatusMessage(message)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(gqlConnect('contactForm')(ContactUs))
