import axios from 'axios'

export const setFirstName = (name) => ({
  type: 'SET_FIRST_NAME',
  payload: name,
})

export const setLastName = (name) => ({
  type: 'SET_LAST_NAME',
  payload: name,
})

export const setEmail = (email) => ({
  type: 'SET_EMAIL',
  payload: email,
})

export const setPhoneNumber = (phone) => ({
  type: 'SET_PHONE_NUMBER',
  payload: phone,
})

export const setCompany = (company) => ({
  type: 'SET_COMPANY',
  payload: company,
})

export const setMessage = (message) => ({
  type: 'SET_MESSAGE',
  payload: message,
})

const setSending = (isSending) => ({
  type: 'SET_SENDING',
  payload: isSending,
})

export const setStatusMessage = (message) => ({
  type: 'SET_STATUS_MESSAGE',
  payload: message,
})

function gtag_report_conversion() {
  window.gtag('event', 'conversion', {
    send_to: 'AW-448171304/XCA9CKzuv_cBEKia2tUB',
  })
  return false
}

export const submitInquiry = (inquiry) => {
  return async (dispatch) => {
    dispatch(setSending(true))
    if (!inquiry.hsPortalId || !inquiry.hsFormGuid) {
      dispatch(
        setStatusMessage(
          'Oh deer, your moose-age could not be sent. To avoid further puns, please try again.',
        ),
      )
      console.log('HS PORTAL ID OR HS FORM GUID NOT FOUND')
      return
    }

    const fields = [
      {
        name: 'firstname',
        value: inquiry.firstName,
      },
      {
        name: 'lastname',
        value: inquiry.lastName,
      },
      {
        name: 'email',
        value: inquiry.email,
      },
      {
        name: 'phone',
        value: inquiry.phone,
      },
      {
        name: 'company',
        value: inquiry.company,
      },
      {
        name: 'message',
        value: inquiry.message,
      },
      {
        name: 'partner_name',
        value: 'Oos',
      },
    ]

    if (inquiry.utmSource) {
      fields.push({
        name: 'utm_source',
        value: inquiry.utmSource,
      })
    }

    if (inquiry.utmMedium) {
      fields.push({
        name: 'utm_medium',
        value: inquiry.utmMedium,
      })
    }

    if (inquiry.utmCampaign) {
      fields.push({
        name: 'utm_campaign',
        value: inquiry.utmCampaign,
      })
    }

    const inquiryRes = await axios.post(
      `https://api.hsforms.com/submissions/v3/integration/submit/${inquiry.hsPortalId}/${inquiry.hsFormGuid}`,
      {
        fields,
      },
    )

    if (inquiryRes.status === 200) {
      gtag_report_conversion(window.location.href)
      dispatch(
        setStatusMessage('Thanks for the message! We’ll be in touch soon.'),
      )
    } else {
      dispatch(
        setStatusMessage(
          'Oh deer, your moose-age could not be sent. To avoid further puns, please try again.',
        ),
      )
      console.log('INQUIRY RES: ', inquiryRes)
    }

    dispatch(setSending(false))
  }
}
